import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

// const siteSwitcherService = require("./../service/siteSwitcherService.js");

// const themeLight = siteSwitcherService.getSiteSetup().theme;

Vue.use(Vuetify, {
  iconfont: "md"
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //primary: "#ff0000",",
        ////accent: "#476B63",
        //// error: "#546B66"
        //"primary--text": "#ff0000"

        primary: "#51702d",
        secondary: "#1f3531",
        accent: "white", //link text
        error: "#1f3531", //item se
        ////secondary: "#394246lected
        info: "#51702d",
        success: "#061a35",
        //warning: "#00F"
        warning: "#fcfbf1", //marker text

        header: "#a3bba0",
        tabs: "#96a992",

        mediaButtonColor: "#879c33",

        filterLegendButtonColor: "#51702d",

        dialogActionButtonColor: "black",
        dialogToobarColor: "#879c33",
        logoTextColor: "#e9e3cd",
        filterButtonColor: "#fae08c",
        mainMenuBarColor: "#51702d",
        menuBarColor: "#879c33",

        langButtonColor: "#e9e3cd",
        tabSliderColor: "#51702d",

        logoColor: "#fff",





      }
    }
  }
});
