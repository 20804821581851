var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"siteKeyAct_wrapper  pb-10 pb-12"},[_c('div',{staticClass:"filterWrapper",attrs:{"xv-if":"!currentCountryNotEnglish"}},[_c('div',{staticClass:"filterBorder"},[_c('v-text-field',{attrs:{"dark":_vm.appSettings.listFilterTabDark === true,"background-color":"filterButtonColor","solo":"","label":"List Filter","prepend-inner-icon":"mdi-text-search-variant","clearable":""},model:{value:(_vm.wordFilter),callback:function ($$v) {_vm.wordFilter=$$v},expression:"wordFilter"}})],1)]),_c('div',{staticClass:"filterWrapperSpacer",staticStyle:{"height":"50px"}}),_c('div',{staticClass:"siteListWrapper"},[_c('v-list',{staticClass:"pt-0",class:[_vm.wordFilterClean ? 'filterRunning' : '']},[_c('v-list-item-group',{attrs:{"color":"info"},model:{value:(_vm.sidebarSiteActPlus),callback:function ($$v) {_vm.sidebarSiteActPlus=$$v},expression:"sidebarSiteActPlus"}},_vm._l((_vm.menuListItems),function(section){return _c('v-list-group',{directives:[{name:"show",rawName:"v-show",value:(
            !section.hideSection &&
              (!_vm.wordFilterClean || _vm.wordFilterCatList[section.key])
          ),expression:"\n            !section.hideSection &&\n              (!wordFilterClean || wordFilterCatList[section.key])\n          "}],key:section.key,class:[
            'catKeyAct_' + section.key,
            _vm.sectionClasses[section.key]
          ],attrs:{"eager":"","xv-model":"section.active","no-action":"","aria-label":("" + (section.title))},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-avatar',{staticClass:"sectionAvatar",attrs:{"size":"50","color":section.setup.color}},[_c('img',{staticStyle:{"height":"40px","width":"35px","object-fit":"contain","overflow":"visible","border-radius":"0!important"},attrs:{"src":section.setup.list_image_obj.marker,"aria-label":("" + (section.title))}})]),_c('v-list-item-content',{attrs:{"eager":""}},[_c('v-list-item-title',{staticClass:"text-header text-wrap font-weight-medium font-weight-normal secondary--text",attrs:{"aria-label":("" + (section.title))}},[_c('engAndTrans',{attrs:{"value":section.title}})],1)],1)]},proxy:true}],null,true)},[_vm._l((section.items),function(site){return [_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
                !_vm.wordFilterClean ||
                  (_vm.wordFilterClean &&
                    ((site.title &&
                      site.title.toLowerCase() &&
                      site.title.toLowerCase().includes(_vm.wordFilterClean)) ||
                      (_vm.currentCountryNotEnglish &&
                        _vm.translatedList[_vm.translationLanguageCode] &&
                        _vm.translatedList[_vm.translationLanguageCode][site.title] &&
                        _vm.translatedList[_vm.translationLanguageCode][site.title]
                          .toLowerCase()
                          .includes(_vm.wordFilterClean))))
              ),expression:"\n                !wordFilterClean ||\n                  (wordFilterClean &&\n                    ((site.title &&\n                      site.title.toLowerCase() &&\n                      site.title.toLowerCase().includes(wordFilterClean)) ||\n                      (currentCountryNotEnglish &&\n                        translatedList[translationLanguageCode] &&\n                        translatedList[translationLanguageCode][site.title] &&\n                        translatedList[translationLanguageCode][site.title]\n                          .toLowerCase()\n                          .includes(wordFilterClean))))\n              "}],key:site.key,class:[
                'listItemAct',
                'ml-0',
                'siteKeyAct_' + site.properties.index,
                _vm.sidebarSite === site.properties.index ? 'currentlyOpen' : ''
              ],attrs:{"eager":"","color":"info","value":1000000 + site.properties.index,"aria-label":("" + (site.title))},on:{"click":function($event){$event.preventDefault();return _vm.focusOnSite(site.properties.index)},"mouseenter":function($event){return _vm.hoverOnListItem(site.properties.index)},"dblclick":function($event){return _vm.loadMoreInfo(site.properties.index)}}},[_c('v-list-item-avatar',{staticClass:"itemAvatar",attrs:{"size":"45","tile":""}},[(site.properties.list_image_obj.marker)?_c('img',{staticStyle:{"height":"40px","width":"40px","object-fit":"contain","overflow":"visible"},attrs:{"src":("" + (site.properties.list_image_obj.marker))}}):_vm._e()]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-header text-wrap  secondary--text"},[_c('engAndTrans',{attrs:{"value":site.title}})],1)],1)],1)]})],2)}),1)],1),(!_vm.sites.length)?_c('center',[_c('br'),_c('div',{staticClass:"ml-4 heading right"},[_vm._v("... no items")])]):_vm._e(),(!_vm.sites.length)?_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"depressed":"","color":_vm.appSettings.mediaButtonColor,"width":_vm.appSettings.drawButtonWidth},on:{"click":function($event){return _vm.resetFilters()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cached")]),_vm._v(" Reset filters ")],1):_vm._e(),_c('div',{staticStyle:{"display":"none"}},[_vm._v(" sidebarSite:"+_vm._s(_vm.sidebarSite)+";; sidebarSiteAct:"+_vm._s(_vm.sidebarSiteAct)+" ")]),_c('br'),_c('br'),_c('br'),_c('br'),_c('br')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }