const devideby = 10000000000;
const acf_library = require("./acf_library");
const mergeItem = require("./mergeItem");
/*
const htmlDecode = function(input) {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};
*/

const constsAndLimits = {
  filterOutSmallGalleryImages: true,
  minHeight: 610,
  minWidth: 610
};

function htmlDecode(encodedString) {
  var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  var translate = {
    nbsp: " ",
    amp: "&",
    quot: '"',
    lt: "<",
    gt: ">"
  };
  return encodedString
    .replace(translate_re, function(match, entity) {
      return translate[entity];
    })
    .replace(/&#(\d+);/gi, function(match, numStr) {
      var num = parseInt(numStr, 10);
      return String.fromCharCode(num);
    });
}

const roundLongLat = a => {
  //return a;
  return Math.round(a * devideby) / devideby;
};

const genGeoIndex = (a, b) => {
  a = Math.round(a * devideby) / devideby;
  b = Math.round(b * devideby) / devideby;
  return "geo" + a + b;
};

const calcClustering = features => {
  //features.forEach
  let multiMarkerList = {};
  let stackedMarkers = {};

  for (let index = 0; index < features.length; index++) {
    //let properties = features[index].properties;

    features[index].properties.stackIndex = false;
    features[index].properties.hasStacking = false;
    features[index].properties.hideForStacking = false;

    try {
      let geoIndex = features[index].properties.geoIndex;
      if (!multiMarkerList[geoIndex]) {
        multiMarkerList[geoIndex] = [];
      } else {
        //item already on this point
        features[index].properties.hideForStacking = true;
      }

      multiMarkerList[geoIndex].push(index);
      if (multiMarkerList[geoIndex].length > 1) {
        stackedMarkers[geoIndex] = multiMarkerList[geoIndex];
      }
    } catch (error) {
      //do nothing
    }
  }

  for (let index = 0; index < features.length; index++) {
    let geoIndex = features[index].properties.geoIndex;

    if (stackedMarkers[geoIndex]) {
      //for manual stack, if title is longer than x, add '...'

      features[index].geometry.coordinates[0] = roundLongLat(
        features[index].properties.Latitude
      );
      features[index].geometry.coordinates[1] = roundLongLat(
        features[index].properties.Longitude
      );

      let stackedIndexLabel = stackedMarkers[geoIndex]
        .map(n => {
          //return n + 1;

          return features[n].properties.indexLabel;
        })
        .join(", ");

      stackedIndexLabel =
        stackedIndexLabel.length > 9
          ? stackedIndexLabel.substring(0, 9) + "..."
          : stackedIndexLabel;

      features[index].properties.stackedIndexLabel = stackedIndexLabel;

      features[index].properties.hasStacking = true;
      features[index].properties.stackIndex = multiMarkerList[geoIndex].indexOf(
        index
      );

      let stackIndex = features[index].properties.stackIndex;

      const offsetAmount = 1;
      const enableMarkerOffsetPatter = false;

      features[index].properties.stackOffset = [
        -1 * offsetAmount * features[index].properties.stackIndex,
        -1 * offsetAmount * features[index].properties.stackIndex
      ];

      //stacking offset stuff;
      if (enableMarkerOffsetPatter) {
        features[index].properties.stackOffset =
          stackIndex === 0
            ? [0, 10]
            : stackIndex === 1
            ? [-5, -5]
            : stackIndex === 2
            ? [5, -5]
            : [0, 10];
      }
    }
  }

  return { features, stackedMarkers };
};

const hackTaxonomy = function(taxonomy) {
  if (taxonomy.marker_institute && taxonomy.marker_institute.length) {
    taxonomy.marker_institute.forEach(row => {
      for (const [key, value] of Object.entries(row)) {
        //console.log(`${key}: ${value}`);
        //this is to fix WYSIWYG editor colums from ACF
        if (key.endsWith("_clean")) {
          if (row.acf[key.replace("_clean", "")]) {
            row.acf[key.replace("_clean", "")].value = value.value;
          }
        }
      }
      /*
        for (let key of row) {

          //if (key.endsWith("_clean")) {
       
       
            if (row.acf[key.replace("_clean", "")]) {

            row.acf[key.replace("_clean", "")].value = row[key].get_field;
          }
      }
*/
    });
  }

  return taxonomy;
};

const buildTaxonomyLookup = ({ /*post*/ taxonomy, targets }) => {
  //this function creates a generic lookup list for all taxonomy, linking to partent post types, and all items;
  let taxonomiesByPostType = {};
  let taxonomyLookup = {};
  for (const key in taxonomy) {
    let lookup = {};
    let lookupById = {};
    let childCountLookup = {};
    let array = taxonomy[key];
    let keys = [];
    array.forEach(item => {
      let { slug, count, id } = item;
      lookup[slug] = item;
      lookupById[id] = item;
      childCountLookup[slug] = count ? count : 0;
      if (count) {
        keys.push(slug);
      }
    });

    taxonomyLookup[key] = {
      lookupById,
      lookup,
      keys,
      keysAll: Object.keys(lookup),
      array: array,
      count: childCountLookup
    };
  }
  targets.forEach(({ postId, taxonomies }) => {
    taxonomies.forEach(taxonomyKey => {
      if (!taxonomiesByPostType[postId]) {
        taxonomiesByPostType[postId] = {};
      }
      taxonomiesByPostType[postId][taxonomyKey] = taxonomyLookup[taxonomyKey];
    });
  });
  taxonomiesByPostType.all = taxonomyLookup;
  return taxonomiesByPostType;
};

const hackPosts = post => {
  //order by titles;
  if (post["markers"] && post["markers"][0]) {
    try {
      let tempMarkers = post["markers"];
      tempMarkers = tempMarkers.map(row => {
        row.tempTitle = row?.title?.rendered ? row?.title?.rendered : row.slug;
        return row;
      });
      tempMarkers = tempMarkers.sort((a, b) =>
        a.tempTitle > b.tempTitle ? 1 : b.tempTitle > a.tempTitle ? -1 : 0
      );
      post["markers"] = tempMarkers;
    } catch (error) {
      //do nothing;
    }
  }

  return post;
};

const formatNonGeoData = linkedDataSets => {
  let formatedData = {};

  let postTypes = Object.keys(linkedDataSets);

  if (postTypes.length) {
    postTypes.forEach(postTypeId => {

try {


      let order = 0;

      let postItems = linkedDataSets[postTypeId];

      if (postItems.length) {
        formatedData[postTypeId] = [];

        postItems.forEach(row => {
          delete row._links;
          delete row.link;
          delete row.comment_status;
          delete row.ping_status;
          delete row.guid;
          delete row.ping_status;
          delete row.ping_status;

          let output = {};

          let { acf, id: postId, featured_image_obj } = row;

          let customData = {};

          for (let key in acf) {
            customData[key] = acf[key];
          }

          let structuredData = acf_library.processAcfData(customData);

          let title = row?.title?.rendered;

          let Point_Name = "";
          let tempTitle = row?.title?.rendered ? row.title.rendered : "";

          try {
            Point_Name = htmlDecode(tempTitle);
          } catch (error) {
            //console.log({tempTitle});
          }

          output = {
            Point_Name,
            ...row,
            postId,
            structuredData,
            featured_thumb: featured_image_obj?.thumb,
            featured_full: featured_image_obj?.href,
            featured_marker_lg: featured_image_obj?.marker_lg,
            order: JSON.parse(JSON.stringify(order)),
            acf,

            title,
            data: row
          };
          order++;

          formatedData[postTypeId].push(output);
        });
      }

  
    } catch (error) {
      //do nothing;
    }

    });
  }

  return formatedData;
};

const genGeoJsonAndConfig = async ({ post, taxonomy }, targets) => {
  taxonomy = hackTaxonomy(taxonomy);
  post = hackPosts(post);
  let linkedDataSets = {};

  try {
    let maptivateSetupObject = {};
    let output = {};
    let geoJsonFeatures = [];
    let sectionArray = [];
    let markerImages = {};
    let setTaxonomySetOrder = [];
    let setListTaxonomySetOrder = [];

    let taxonomiesByPostType = buildTaxonomyLookup({ post, taxonomy, targets });
    if (taxonomiesByPostType) {
      //do nothing;
    }
    let listCategoryLookup = {};

    //let sectionTypes = ["main_marker", "artwork", "signage", "other"];
    let geoJsonByType = {};
    targets.forEach(
      ({
        postId,
        taxonomies,
        listTaxonomies,
        sectionTaxonomies,
        geoData,
        isSetupObject,
        isLinkedData
      }) => {
        if (isLinkedData === true) {
          if (post[postId]) {
            linkedDataSets[postId] = post[postId];
          }
        }
        if (taxonomy[listTaxonomies]) {
          //do nothing;

          taxonomy[listTaxonomies].forEach(row => {
            row.list_section_slug = row.slug;
            row.section_type = "main_marker";
            row.list_section_type = "main_marker";
            row.title = row.name;

            row.title = row.name;
            row.padding = "15px";

            listCategoryLookup[row.slug] = row;

            setListTaxonomySetOrder.push(row.slug);
          });
        }

        if (isSetupObject === true && post[postId] && post[postId][0]) {
          maptivateSetupObject = post[postId][0];
        }

        if (geoData === true && post[postId]) {
          let setPost = post[postId];
          let setTaxonomy = taxonomy[sectionTaxonomies];

          let listTaxonomy =
            listTaxonomies && taxonomy[listTaxonomies]
              ? taxonomy[listTaxonomies]
              : null;

          setTaxonomySetOrder = [
            ...setTaxonomySetOrder,
            ...setTaxonomy.map(row => row.slug)
          ];

          let setTaxonomyObj = setTaxonomy.reduce(function(map, obj) {
            map[obj.id] = obj;
            //map[obj.slug] = obj;
            return map;
          }, {});
          sectionArray = [...sectionArray, ...setTaxonomy];
          setPost.forEach(post => {
            /*
            let { markersection, artwork_section } = post;

            if (artwork_section && !markersection) {
              markersection = artwork_section;
            }*/

            let { markersection, artwork_section } = post;
            markersection = post[sectionTaxonomies];

            let listTaxonomiesValue = post[listTaxonomies];

            let markerListSectionObj = false;
            //and menu list taxonomy
            if (listTaxonomy && listTaxonomiesValue && listTaxonomiesValue[0]) {
              markerListSectionObj = listTaxonomy.filter(row => {
                return row.id === listTaxonomiesValue[0];
              })[0];

              //;

              //Menu;
            }

            if (artwork_section && !markersection) {
              markersection = artwork_section;
            }

            let markerSectionObj = false;
            if (
              setTaxonomyObj &&
              markersection[0] &&
              setTaxonomyObj[markersection[0]]
            ) {
              markerSectionObj = setTaxonomyObj[markersection[0]];
            } else {
              //markerSectionObj = setTaxonomy[0];
              console.error("error, post has no section", {
                post,
                markersection,
                setTaxonomyObj
              });
            }

            if (
              markerSectionObj &&
              post &&
              post.cust_location &&
              post.cust_location[1] &&
              post.status == "publish" &&
              (markerSectionObj?.featured_image_obj?.id ||
                post?.featured_image_obj?.id)
            ) {
              let section_type = markerSectionObj?.acf?.type?.value;

              let section_id = markerSectionObj?.id;

              let render_icons = markerSectionObj?.acf?.render_icons?.value
                ? markerSectionObj?.acf?.render_icons?.value
                : false;

              let section_colour = markerSectionObj?.acf?.colour?.value;
              let section_name = markerSectionObj?.name;

              section_colour = section_colour ? section_colour : "black";

              let section_slug = markerSectionObj?.slug;

              let section_prfixCode = markerSectionObj?.acf?.PrifixCode?.value
                ? markerSectionObj?.acf?.PrifixCode?.value
                : section_id;

              let icon_obj = null; //{id, url, title}
              let list_image_obj = null; //{id, url, title}

              let {
                id,
                href,
                title,
                thumb,
                marker,
                //marker_lg: marker_lg_old,
                //marker_md: marker_md_old,

                marker_lg_s: marker_lg,
                marker_md_s: marker_md,
                marker_vs,
                mo_scale_factor_media
              } =
                post?.featured_image_obj?.href && post?.featured_image_obj?.id
                  ? post.featured_image_obj
                  : markerSectionObj.featured_image_obj;

              let scale_factor = false;
              let isArtwork = false;
              if (section_type === "artwork") {
                isArtwork = true;
              }

              if (!isNaN(post?.acf?.MO_artwork_scale_factor?.value)) {
                try {
                  scale_factor = parseFloat(
                    post?.acf?.MO_artwork_scale_factor?.value
                  );
                  if (scale_factor === 1 || scale_factor === 0) {
                    scale_factor = false;
                  }
                } catch (error) {
                  //do nothing;
                }
              }

              let typeSlug = markerSectionObj.taxonomy;

              if (id && href) {
                let uniqueKey = `${id}_${typeSlug}_${section_slug}_title`;

                if (!mo_scale_factor_media || mo_scale_factor_media === 1) {
                  mo_scale_factor_media = null;
                }

                icon_obj = {
                  id,
                  href,
                  title,
                  thumb,
                  marker,
                  section_slug,
                  typeSlug,
                  marker_vs,
                  marker_lg,
                  marker_md,
                  mo_scale_factor_media
                };
                if (isArtwork === true && marker_lg && marker_md) {
                  //do nothing;
                }
                if (isArtwork === true) {
                  icon_obj.isArtwork = true;
                  //icon_obj.marker_lg = marker_lg;
                  //icon_obj.marker_md = marker_md;
                  //icon_obj.section_slug = section_slug;

                  //console.log(marker_vs);
                }
                if (scale_factor !== false) {
                  icon_obj.scale_factor = scale_factor;
                }
                markerImages[uniqueKey] = icon_obj;
              }

              list_image_obj = icon_obj;
              if (post?.list_image_obj?.id) {
                list_image_obj = post.list_image_obj;
              }

              try {
                //this filters our list icons for the list.
                if (post.media_urls.length && list_image_obj?.id) {
                  post.media_urls = post.media_urls.filter(row => {
                    return row.ID != list_image_obj?.id;
                  });
                }
              } catch (error) {
                //do nothing;
              }

              try {
                if (post.media_urls.length && post.featured_media) {
                  //post.featured_media //id
                  post.media_urls = post.media_urls.filter(row => {
                    return row.ID != post.featured_media;
                  });
                }
              } catch (error) {
                //do nothing;
              }

              try {
                //filter out small gallery images;
                if (
                  post.media_urls.length &&
                  constsAndLimits.filterOutSmallGalleryImages === true
                ) {
                  post.media_urls = post.media_urls.filter(row => {
                    let okSize = true;
                    let width = row?.size?.mv_gallery_wide[1];
                    let height = row?.size?.mv_gallery_wide[2];
                    if (
                      (width &&
                        constsAndLimits.minWidth &&
                        constsAndLimits.minWidth > width) ||
                      (height &&
                        constsAndLimits.minWidth &&
                        constsAndLimits.minWidth > height)
                    ) {
                      okSize = false;
                    }
                    return okSize === true;
                  });
                }
              } catch (error) {
                //do nothing;
              }

              //checking for a wide image in gallery;
              let maxImageWidth = 0;
              let maxImageHeight = 0;
              if (post.media_urls.length) {
                post.media_urls.forEach(image => {
                  try {
                    let { size } = image;
                    let { mv_gallery_wide, mv_popup_thumb } = size;
                    if (mv_gallery_wide && mv_gallery_wide[1]) {
                      let tempWidth = mv_gallery_wide[1];
                      let tempHeight = mv_gallery_wide[2];

                      let tempThumbWidth = mv_popup_thumb[1];
                      let tempThumbHeight = mv_popup_thumb[2];

                      try {
                        tempWidth = parseFloat(tempWidth);
                        if (tempWidth > maxImageWidth) {
                          maxImageWidth = tempWidth;
                        }

                        tempHeight = parseFloat(tempHeight);
                        if (tempHeight > maxImageHeight) {
                          maxImageHeight = tempHeight;
                        }

                        try {
                          if (tempWidth && tempHeight) {
                            image.fullAspectRatios = tempWidth / tempHeight;
                            image.fullWidth = tempWidth;
                            image.fullHeight = tempHeight;
                          }

                          if (tempThumbWidth && tempThumbHeight) {
                            image.thumbAspectRatios =
                              tempThumbWidth / tempThumbHeight;
                            image.thumbWidth = tempThumbWidth;
                            image.thumbHeight = tempThumbHeight;
                          }

                          const srcThumbHeight = 420;
                          const srcThumbWidth = 450;

                          if (
                            image.thumbWidth == srcThumbWidth &&
                            image.thumbHeight == srcThumbHeight
                          ) {
                            //look at ratio
                            if (image.fullAspectRatios < 0.85) {
                              image.aspectRatiosClass = "tallAspectImage";
                            } else if (image.fullAspectRatios < 1.1) {
                              image.aspectRatiosClass = "mediumTallAspectImage";
                            }
                          }
                        } catch (error) {
                          //do nothing;
                        }
                      } catch (error) {
                        //do nothing;
                      }
                    }
                  } catch (error) {
                    //do nothing;
                  }
                });
              }
              post.galleryHasMobileWidth = false;
              post.galleryHasComputerWidth = false;
              if (maxImageWidth) {
                if (maxImageWidth > 400 || maxImageHeight > 400) {
                  post.galleryHasMobileWidth = true;
                }
                if (maxImageWidth > 1000 || maxImageHeight > 600) {
                  post.galleryHasComputerWidth = true;
                }
              }

              let customData = {};

              //setTaxonomyObj
              const mergeInTaxCustData = true;
              if (mergeInTaxCustData) {
                let taxonomyObjAcf = markerListSectionObj.acf;
                for (let key in taxonomyObjAcf) {
                  if (
                    taxonomyObjAcf[key] &&
                    key !== "location" &&
                    key.startsWith("M_")
                  ) {
                    customData[key] = taxonomyObjAcf[key];
                  }
                }
              }

              for (let key in post.acf) {
                if (post.acf[key] && key !== "location") {
                  customData[key] = post.acf[key];
                }
              }

              let structuredData = acf_library.processAcfData(customData);

              //post.cust_location[1] = roundLongLat(post.cust_location[1]);
              //post.cust_location[0] = roundLongLat(post.cust_location[0]);

              let geoIndex = genGeoIndex(
                post.cust_location[1],
                post.cust_location[0]
              );

              let Site_Marker_Number_numPart = customData?.M_ST_Site_ID?.value
                ? customData.M_ST_Site_ID?.value
                : "post_" + post.id;

              let Site_Marker_Number_Label = Site_Marker_Number_numPart;
              let Site_Marker_Number =
                section_prfixCode + "__" + Site_Marker_Number_numPart;

              let Point_Name = "";
              let tempTitle = post?.title?.rendered ? post.title.rendered : "";

              try {
                Point_Name = htmlDecode(tempTitle);
              } catch (error) {
                //console.log({tempTitle});
              }

              if (Point_Name.includes("|||")) {
                Point_Name = Point_Name.split("|||")[1].trim();
              }

              let name = Point_Name;

              let listSectionDetails = {};

              if (
                markerListSectionObj &&
                markerListSectionObj.title &&
                markerListSectionObj.slug
              ) {
                let Institute_Name_And_Point_Name =
                  markerListSectionObj.title + " - " + Point_Name;

                let Institute_Code_And_Point_Name =
                  markerListSectionObj.slug.toUpperCase() + " - " + Point_Name;

                listSectionDetails = {
                  Institute_Name_And_Point_Name,
                  Institute_Code_And_Point_Name
                };

                name = Institute_Name_And_Point_Name; //name is used by screen reader;
              }

              let custom_icon_style = "none";

              let taxNames = {};
              let taxSlugs = {};

              try {
                //taxonomiesByPostType
                taxonomies.forEach(taxSlug => {
                  taxSlugs[taxSlug] = [];
                  taxNames[taxSlug] = [];

                  if (post[taxSlug] && post[taxSlug].length) {
                    post[taxSlug].forEach(taxId => {
                      try {
                        let slug =
                          taxonomiesByPostType.all[taxSlug].lookupById[taxId]
                            .slug;
                        let name =
                          taxonomiesByPostType.all[taxSlug].lookupById[taxId]
                            .name;
                        if (slug) {
                          taxSlugs[taxSlug].push(slug);
                          taxNames[taxSlug].push(name);
                        }
                      } catch (error) {
                        //do nothing;
                      }
                    });
                  }
                });
              } catch (error) {
                //do nothing;
              }

              let extra = {};

              if (
                structuredData?.linked_post?.M_LINKED_POST_linkedMarker?.value
              ) {
                extra.M_LINKED_POST_linkedMarker =
                  structuredData?.linked_post?.M_LINKED_POST_linkedMarker?.value;
              }

              if (["keepertalks", "transport"].includes(section_slug)) {
                custom_icon_style = "tall";
              }

              let markerObj = {
                // feature for Mapbox DC
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [post.cust_location[1], post.cust_location[0]]
                },

                properties: {
                  name,
                  Point_Name,
                  mv_tooltip: Point_Name,
                  mv_label: Point_Name,

                  stackIndex: Site_Marker_Number,
                  hasStacking: false,
                  hideForStacking: false,
                  geoIndex: geoIndex,
                  hideMarkerButMakeClickable: "false",

                  ...listSectionDetails,
                  Site_Marker_Number,
                  Site_Marker_Number_Label,

                  Longitude: post.cust_location[0],
                  Latitude: post.cust_location[1],

                  post_id: post.id,

                  slug: post.slug,
                  content: post.content,
                  media:
                    post.media_urls && post.media_urls.length
                      ? post.media_urls
                      : null,
                  //customData,
                  structuredData,
                  date: post.date,
                  modified: post.modified,
                  section_type,
                  section_id,
                  section_slug,
                  custom_icon_style,
                  section_colour,
                  render_icons,
                  section_name,
                  list_section_slug: markerListSectionObj.slug
                    ? markerListSectionObj.slug
                    : null,
                  list_section_id: markerListSectionObj.id
                    ? markerListSectionObj.id
                    : null,

                  list_section_title: markerListSectionObj.title
                    ? markerListSectionObj.title
                    : null,

                  post_type_slug: postId,

                  icon_obj,
                  icon_id: icon_obj?.id ? icon_obj?.id : false,

                  list_image_obj: list_image_obj,
                  list_image_id: list_image_obj?.id
                    ? list_image_obj?.id
                    : false,

                  galleryHasMobileWidth: post.galleryHasMobileWidth,
                  galleryHasComputerWidth: post.galleryHasComputerWidth,

                  postTaxonomy: { taxNames, taxSlugs },
                  ...extra
                }
              };

              if (!geoJsonByType["geoJson_" + section_type]) {
                geoJsonByType["geoJson_" + section_type] = {
                  type: "FeatureCollection",
                  features: []
                };
              }
              geoJsonFeatures.push(markerObj);
              geoJsonByType["geoJson_" + section_type].features.push(markerObj);
            }
          });
        }
      }
    );

    let sectionObj = {};
    let categoryLookup = {};

    let sectionOrder = 0;
    sectionArray.forEach(row => {
      let {
        id,
        count,
        description,
        name,
        slug,
        taxonomy,
        featured_image_obj,
        list_image_obj,
        acf
      } = row;

      sectionOrder++;

      let SectionLink = acf?.SectionLink?.value;

      let hide_layer_checkbox = acf?.hide_layer_checkbox?.value
        ? acf?.hide_layer_checkbox?.value
        : false;

      let hidden_by_default = acf?.hidden_by_default?.value
        ? acf?.hidden_by_default?.value
        : false;

      let render_icons = acf?.render_icons?.value
        ? acf?.render_icons?.value
        : false;

      //add anchor data;
      let sectionIconAnchor = false;
      try {
        sectionIconAnchor =
          acf["icon-anchor"] && acf["icon-anchor"]?.value
            ? acf["icon-anchor"]?.value
            : false;
      } catch (error) {
        //do nothing
      }

      let colour = acf?.colour?.value;
      colour = colour ? colour : "black";
      let type = acf?.type?.value;

      if (!list_image_obj?.href) {
        list_image_obj = featured_image_obj;
      }

      let options = acf_library.processAcfTaxonomy(acf);

      var visible = type != "artwork" && type != "other" ? true : false;

      if (hide_layer_checkbox === true) {
        visible = false;
      }

      if (count) {
        categoryLookup[slug] = {
          id,
          key: slug,
          count,
          description,
          title: name,
          name,
          section_slug: slug,
          taxonomy,
          render_icons,
          list_image_obj,
          hide_layer_checkbox,
          hidden_by_default,
          featured_image_obj,
          SectionLink: SectionLink,
          color: colour,
          section_type: type,
          sectionIconAnchor,
          padding: "15px",
          visible,
          options,
          sectionOrder
        };

        sectionObj[slug] = {
          id,
          key: slug,
          count,
          description,
          title: name,
          name,
          section_slug: slug,
          taxonomy,
          hide_layer_checkbox,
          hidden_by_default,
          render_icons,
          list_image_obj,
          featured_image_obj,
          SectionLink: SectionLink,
          sectionIconAnchor,
          color: colour,
          section_type: type,
          padding: "15px",
          options,
          sectionOrder
        };
      }
    });

    /*
    //sort by cat order !!!
    let rawFeatues = geoJsonByType.geoJson_main_marker.features;
    let features = [];
    setTaxonomySetOrder.forEach(orderKey => {
      if (categoryLookup[orderKey]) {
        let key = categoryLookup[orderKey].key;
        let cat = categoryLookup[key];
        features = [
          ...features,
          ...rawFeatues.filter(
            point => point.properties.section_slug == cat.section_slug
          )
        ];
      }
    });
    */
    //setListTaxonomySetOrder

    //sort by cat order !!!
    let rawFeatues = geoJsonByType.geoJson_main_marker.features;
    let features = [];
    setListTaxonomySetOrder.forEach(orderKey => {
      if (listCategoryLookup[orderKey]) {
        //let key = listCategoryLookup[orderKey].key;
        let cat = listCategoryLookup[orderKey];
        features = [
          ...features,
          ...rawFeatues.filter(
            point => point.properties.list_section_slug == cat.list_section_slug
          )
        ];
      }
    });

    let temp = calcClustering(features);

    let stackedMarkers = temp.stackedMarkers;
    features = temp.features;

    geoJsonByType.geoJson_main_marker.features = features;

    let id = 0;
    geoJsonByType.geoJson_main_marker.features.forEach(row => {
      row.id = id;
      id++;
    });

    if (geoJsonByType?.geoJson_signage?.features) {
      geoJsonByType.geoJson_signage.features.forEach(row => {
        row.id = id;
        id++;
      });
    }
    if (geoJsonByType?.geoJson_artwork?.features) {
      geoJsonByType.geoJson_artwork.features.forEach(row => {
        row.id = id;
        id++;
      });
    }

    //do the clone content bit;  this looks for a value "M_LINKCLONEOBJ_POINT", that links to the post id, if found it will try to clone the content.
    geoJsonByType.geoJson_main_marker.features.forEach(row => {
      try {
        if (
          !isNaN(
            row?.properties?.structuredData?.clonedata?.M_LINKCLONEOBJ_POINT
              ?.value
          )
        ) {
          //has valid linked clone item;
          let connectedItemPostId =
            row?.properties?.structuredData?.clonedata?.M_LINKCLONEOBJ_POINT
              ?.value;
          let linkedItem = null;
          geoJsonByType.geoJson_main_marker.features.filter(item => {
            if (item?.properties?.post_id === connectedItemPostId) {
              linkedItem = item;
            }
          });
          row = mergeItem.merge(row, linkedItem);
        }
      } catch (error) {
        //do nothing;
      }
    });

    /*
    lookup key
let data = geoJsonByType.geoJson_main_marker.features.map(row=>{


  
  let {Site_Marker_Number,Institute_Name_And_Point_Name , section_name, Point_Name} = row.properties;
return {Site_Marker_Number,Institute_Name_And_Point_Name , section_name, Point_Name};

});
*/

    let formatedLinkedData = formatNonGeoData(linkedDataSets);

    output = {
      formatedLinkedData,

      taxonomiesByPostType,
      maptivateSetupObject,
      stackedMarkers,
      sections: sectionObj,
      markerImages,
      ...geoJsonByType,
      categoryLookup,
      categoryOrder: setTaxonomySetOrder,
      listCategoryLookup
    };

    console.log(output);
    console.log({ geoJsonByType });
    console.log({ formatedLinkedData });

    return output;
  } catch (error) {
    console.error("errorC", error);
  }
};

module.exports.genGeoJsonAndConfig = genGeoJsonAndConfig;
