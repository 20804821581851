<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="
        $vuetify.breakpoint.xsOnly
          ? '95%'
          : $vuetify.breakpoint.smAndDown
          ? 550
          : 650
      "
    >
      <template v-slot:activator="{ on, attrs }">
        <span
          v-show="false"
          v-bind="attrs"
          v-on="on"
          class=" "
          @click="scrollToSiteDelay()"
        >
          Layer Info
        </span>
      </template>

      <v-card>
        <v-card-title
          v-if="true"
          :style="{
            color: 'black',
            background: 'menuBarColor'
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          {{ siteObject.title }} Maptivate Layers
        </v-card-title>

        <v-row no-gutters class="secondary--text " style="">
          <v-col
            cols="12"
            md="12"
            class="pt-3 px-4 imageWrapper"
            id="LayerInfoContentWrapper"
            @scroll="onScroll"
          >
            <LayerInfo_text  />
          </v-col>
        </v-row>

        <v-card-actions>
          <transition name="fade" mode="out-in">
            <v-btn
              v-show="!atTop"
              class="v-fade"
              depressed
              color="primary"
              @click="scrollToSite(false)"
            >
              Top
            </v-btn>
          </transition>

          <v-spacer></v-spacer>

          <v-btn depressed color="primary" @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../../main";
import { mapState } from "vuex";

import LayerInfo_text from "./LayerInfo_text.vue";


export default {
  name: "LayerInfoModal",
  components: {
    LayerInfo_text
  },
  data() {
    return {
      atTop: true,
      atBottom: false,
      dontShowAgain: false,
      dialog: false,
      title: "LayerInfo"
    };
  },
  computed: {
    ...mapState({
      siteId: state => state.global.siteId,

      appSettings: state => state.global.appSettings,
      siteObject: state => state.global.siteObject
    })
  },
  created() {
    eventBus.$on("loadLayerInfoDialog", scrollTo => {
      this.dialog = true;

      setTimeout(() => {
        this.scrollToSite(scrollTo);
      }, 500);
    });
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let atBottom = false;
      let atTop = false;
      if (scrollTop + clientHeight >= scrollHeight) {
        atBottom = true;
      }
      if (scrollTop < 20) {
        atTop = true;
      }

      this.atTop = atTop;
      this.atBottom = atBottom;
    },
    updateDontShowCheckbox() {
      localStorage.setItem("dontShowWelcome", this.dontShowAgain);
    },
    scrollToSiteDelay() {
      setTimeout(() => {
        this.scrollToSite();
      }, 100);
    },
    scrollToSite(newValue) {
      let element = `topOfLayerInfoSection`;

      if (newValue) {
        element = `aboutHeading_${newValue}`;

        try {
          window.mvAnalyticEvent("ui", "layerInfoLoaded", `${newValue}`, false);
        } catch (error) {
          //do nothing;
        }
      }

      var AboutContentWrapper = document.getElementById(
        "LayerInfoContentWrapper"
      );
      var heading = document.getElementById(element);

      // Scroll the 'myDiv' to bring the 'heading' element into view
      AboutContentWrapper.scrollTop =
        heading.offsetTop - AboutContentWrapper.offsetTop;
    }
  }
};
</script>

<style>
.imageWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.welcomeText img {
  display: block;
}

.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}

@media only screen and (max-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }
}

@media only screen and (max-width: 760px) {
  .imageWrapper {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
  }
}

.v-fade {
  display: inherit !important; /* override v-show display: none */
  transition: opacity 0.5s;
}

.v-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}
</style>
