


const sites = [
  {
    index: 0,
    key: "abc",
    title: "Lone Pine Koala Sanctuary",
    titleShort: "Lone Pine Koala Sanctuary",
    short: "Lone Pine Koala Sanctuary",

    titleShortLine1: "Lone Pine",
    titleShortLine2: "Koala Sanctuary",
    maptivateUrl: "https://lonepine.maptivateapp.com.au",
    url: "https://lonepinekoalasanctuary.com/",
    mapurl: "https://lonepinekoalasanctuary.com/",
    cmsUrl: "https://cms01.maptivateapp.com.au/lone-pine-koala-sanctuary",
    editPost:
      "https://cms01.maptivateapp.com.au/lone-pine-koala-sanctuary/wp-admin/post.php?post=",
    siteSetup: require("../service/sites/abc/siteSetupAbc.js"),

    subsiteNumber: "2",
    subsiteId: "lone-pine-koala-sanctuary",
    shareTitle: "Lone Pine Koala Sanctuary",
    shareDescription:""
  }
];

const defaultSiteKey = sites[0].key;
const siteLookup = sites.reduce(function(map, obj) {
  map[obj.key] = obj;
  return map;
}, {});

const getId = () => {
  try {
    let id = window.siteMode;

    if (
      id &&
      typeof id === "string" &&
      id.length &&
      id.length < 10 &&
      siteLookup &&
      siteLookup[id]
    ) {
      return id;
    } else {
      throw { error: true };
    }
  } catch (error) {
    return defaultSiteKey;
  }
};

const currentSiteId = getId();
const currentSiteObj = siteLookup[currentSiteId];

module.exports.getId = () => {
  return currentSiteId;
};
module.exports.getSites = () => {
  return JSON.parse(JSON.stringify(sites));
};
module.exports.getSiteObj = () => {
  let setup = { ...currentSiteObj };

  delete setup.siteSetup;

  return setup;
};

module.exports.getSiteSetup = () => {
  let setup = { ...currentSiteObj.siteSetup };

  try {
    setup.global.appSettings.wpEditSite = currentSiteObj.editPost;
  } catch (error) {
    //do nothing;
  }

  return setup;
};
