var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.xsOnly
        ? '95%'
        : _vm.$vuetify.breakpoint.smAndDown
        ? 550
        : 650},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:" ",on:{"click":function($event){return _vm.scrollToSiteDelay()}}},'span',attrs,false),on),[_vm._v(" Layer Info ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(true)?_c('v-card-title',{staticClass:"  xlighten-2 secondary--text text-center justify-center siteHeadingText",style:({
          color: 'black',
          background: 'menuBarColor'
        })},[_vm._v(" "+_vm._s(_vm.siteObject.title)+" Maptivate Layers ")]):_vm._e(),_c('v-row',{staticClass:"secondary--text ",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-3 px-4 imageWrapper",attrs:{"cols":"12","md":"12","id":"LayerInfoContentWrapper"},on:{"scroll":_vm.onScroll}},[_c('LayerInfo_text')],1)],1),_c('v-card-actions',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.atTop),expression:"!atTop"}],staticClass:"v-fade",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.scrollToSite(false)}}},[_vm._v(" Top ")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }