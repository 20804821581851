import Vue from "vue";
import App from "./App.vue";

//import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueScrollTo from "vue-scrollto";
import VueYouTubeEmbed from "vue-youtube-embed";
import browserDetect from "vue-browser-detect-plugin";
import VueSocialSharing from "vue-social-sharing";

import * as Sentry from "@sentry/vue";

const eventBusItems = {
  nav: [
    "nav_sideBar_openSiteList",
    "nav_sideBar_openMoreInfo",
    "nav_layout_openMenu",
    "nav_layout_closeMenu",
    "nav_map_flyToOpenPopUp",
    "nav_map_reset",
    "nav_siteList_scrollToTop"
  ]
};

if (eventBusItems) {
  //do nothing yet
}

//Logging
//import * as Sentry from "@sentry/vue";
//import { Integrations } from "@sentry/tracing";

import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import VueGtag from "vue-gtag";
//import VueAnalytics from "vue-analytics";

Vue.config.productionTip = false;
Vue.use(VueSocialSharing);

Vue.use(VueYouTubeEmbed);
Vue.use(browserDetect);

Vue.use(VueScrollTo, {
  //container: ".tabcontent",
  container: ".siteListWrapper",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});


try {
  if (window?.enableSentryLogs===true) {
    Sentry.init({
      Vue,
      dsn:
        "S-XXX_SentryIoKey_XXX",
      integrations: [
        Sentry.browserTracingIntegration({}),
        Sentry.replayIntegration()
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
} catch (error) {
  //do nothing;
}

Vue.component("vue-json-pretty", VueJsonPretty);

Vue.use(VueGtag, {
  config: { id: "G-XXX_GoogleAnalyticsKey_XXX" } //sovereignhill prod G-FBG26QL0SC;
});

export const eventBus = new Vue();

new Vue({
  //router,
  store,
  vuetify,

  render: h => h(App)
}).$mount("#app");
