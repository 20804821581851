<template>
  <div v-if="ready">
    <ToggleGeoButton :isReady="true" :dialog="true"></ToggleGeoButton>
  </div>
</template>

<script>
import Vue from "vue";

import ToggleGeoButton from "./ToggleGeoButton.vue";

export default Vue.extend({
  components: { ToggleGeoButton },
  data: () => ({
    ready: false
  }),

  props: {
    vuetifyCopy: {
      required: true,
      type: Object
    }
  },

  computed: {},
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    this.ready = true;
  },

  methods: {}
});
</script>
