const theme = {

};




const global = {
  appSettings: {
  


  }
};

module.exports = {
  global,
  theme
};
