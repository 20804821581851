<template>
  <div v-if="isDevEnviroment && postid">
      <v-btn
        v-if="isDevEnviroment"
        @click="editWpItem(postid)"
        depressed
        :color="appSettings.mediaButtonColor"
        :width="300"
        class="ma-2 white--text"
      >
        <v-icon left>mdi-pen</v-icon>
        Cust WP Edit
      </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "mvWpEditButton",
  components: {},
  computed: {
    ...mapState({
      formatedLinkedData: state => state.markers.formatedLinkedData,
      isDevEnviroment: state => state.navigation.isDevEnviroment,
      siteObject: state => state.global.siteObject,

      appSettings: state => state.global.appSettings,

    }),


  },
  data: () => ({
    ready: false,
    audioPlayIcon: "false"
  }),
  props: {
    postid: Number,
  },
  methods: {

    editWpItem(postid) {
      //console.log("editWpItem", { post_id: properties.post_id });

      //let route = this.$router.resolve({ path: "/contact" });
      if (postid) {
        window.open(
          //`https://cms01.maptivateapp.com.au/lone-pine-koala-sanctuary/wp-admin/post.php?post=${properties.post_id}&action=edit`
          `${this.siteObject.editPost}${postid}&action=edit`
        );
      }
    },


  }
};
</script>
<style></style>
