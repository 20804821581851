<template>
  <div>
    layoutOptions:{{ layoutOptions }}
    <div
      v-if="post && title && title && wrapperClass"
      :class="['mvWpContentBlockWrapper', wrapperClass]"
    >
      <h1 v-show="showTitle">{{ title }}</h1>
      <div>
        <v-row>


          <v-col v-if="featured_image_obj"  v-show="showImageAbove" :class="imageColsClass">
              <v-img :src="featured_image_obj.mv_gallery_wide"></v-img>
          </v-col>

          <v-col  :class="textBodyColsClass">
            <div ref="bodyHtmlElement" v-html="body"></div>
          </v-col>

          <v-col v-if="featured_image_obj"  v-show="!showImageAbove"  :class="imageColsClass">
              <v-img :src="featured_image_obj.mv_gallery_wide"></v-img>
          </v-col>


        </v-row>
      </div>
      <mvWpEditButton :postid="post.postId"></mvWpEditButton>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import mvWpEditButton from "../mvWpEditButton.vue";

import contentMountMixins from "./mixins/content-mount-mixins";

function sanitizeClassName(className) {
  // Replace any invalid characters (anything except letters, numbers, hyphens, and underscores)
  let sanitized = className.replace(/[^a-zA-Z0-9-_]/g, "");

  // If the className starts with a digit, prepend an underscore
  if (/^[0-9]/.test(sanitized)) {
    sanitized = "_" + sanitized;
  }

  return sanitized;
}

export default {
  name: "mvWpContentBlock",
  mixins: [contentMountMixins],

  components: { mvWpEditButton },

  data: () => ({
    ready: false,
    mountTargets: ["NavButton"]
  }),
  props: {
    classname: String,
    posttype: String,
    slug: String
  },

  computed: {
    wrapperClass() {
      let { classname, posttype, slug } = this;

      let wrapperClass = `wpconent_${classname}_${posttype}_${slug}`;

      let sanitizedClassName = sanitizeClassName(wrapperClass);

      return sanitizedClassName;
    },
    ...mapState({
      formatedLinkedData: state => state.markers.formatedLinkedData,
      isDevEnviroment: state => state.navigation.isDevEnviroment
    }),

    post() {
      let post = false;
      try {
        if (this.formatedLinkedData[this.posttype]) {
          let posts = this.formatedLinkedData[this.posttype];
          try {
            let [tempPost] = posts.filter(row => row.slug == this.slug);
            if (tempPost?.title) {
              post = tempPost;
            }
          } catch (error) {
            //do nothing;
          }
          try {
            let [tempPost] = posts.filter(row => row.title == this.slug);
            if (tempPost?.title) {
              post = tempPost;
            }
          } catch (error) {
            //do nothing;
          }
        }
      } catch (error) {
        //do nothing;
      }

      console.log({ post });
      return post;
    },
    title() {
      return this?.post?.title;
    },

    has() {
      let output = {};
      let body = this?.post?.content?.rendered;
      /*
      if (body?.includes("###NavButton###")) {
        output.NavButton = true;
      }
      */

      this.mountTargets.forEach(row => {
        if (body?.includes(`###${row}###`)) {
          output[row] = true;
        }
      });
      return output;
    },

    body() {
      let body = this?.post?.content?.rendered;

      //this look for terms inside the post body, and replaces them with empty divs to have vue elments mounted on them.
      this.mountTargets.forEach(row => {
        let pattern = `###${row}###`;
        let targetId = `${this.wrapperClass}_${row}Target`;

        if (body?.includes(pattern)) {
          body = body.replace(pattern, `<div id='${targetId}'></div>`);
        }
      });

      return body;
    },

    featured_image_obj() {
      return this?.post?.featured_image_obj?.marker_vs
        ? this?.post?.featured_image_obj
        : false;
    },

    showTitle() {
      return this.post?.acf?.M_C_YN_ShowTitle?.value;
    },
    layoutOptions() {
      let value = this.post?.acf?.M_C_S_Layout?.value;

      let valid = [
        "NoImage",
        "ImageLeft",
        "ImageRight",
        "ImageAbove",
        "ImageBelow"
      ];

      if (!value || !valid.includes(value)) {
        value = "NoImage";
      }

      return value;
    },

    textBodyColsClass() {
      let classes = ["col-12", "col-sm-6"];

      if (
        !this.featured_image_obj ||
        ["NoImage", "ImageAbove", "ImageBelow"].includes(this.layoutOptions)
      ) {
        classes = ["col-12"];
      }
      return classes;
    },

    imageColsClass() {
      let classes = ["col-12", "col-sm-6"];

      if (
        !this.featured_image_obj ||
        ["NoImage", "ImageAbove", "ImageBelow"].includes(this.layoutOptions)
      ) {
        classes = ["col-12"];
      }
      return classes;
    },
    showImageAbove() {
      let show = false;

      if (
        this.featured_image_obj &&
        ["ImageAbove", "ImageLeft"].includes(this.layoutOptions)
      ) {
        show = true;
      }
      return show;
    }
  },

  methods: {},
  async mounted() {
    console.log("Added");
    setTimeout(() => {
      this.mountDynamicElements();
    }, 300);
  }
};
</script>
<style></style>
